import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1>Website đang trong chế độ bảo trì.</h1>
    <p>Trong thời gian này, bạn vui lòng tham gia các kênh sau để sử dụng các tài nguyên của Đề thi UEH.</p>
    <li>
      Trang <strong>Đề thi UEH</strong>: <a href="http://bit.ly/2J0zVhE" rel="noopener noreferrer" target="_blank">https://www.facebook.com/dethi.UEH/</a>
    </li>
    <li>
      Nhóm <strong>Góc Học Tập UEH</strong>: <a href="http://bit.ly/2IXC6CA" rel="noopener noreferrer" target="_blank">https://www.facebook.com/groups/GocHocTapUEH/</a>
    </li>
  </Layout>
)

export default NotFoundPage
